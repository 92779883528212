@import "general/colors";
@import "general/newTypography";
@import "general/transitions";
.new-history-area {
  padding: 5em 0;
  .title {
    @include title-area;
    text-align: left;
    color: $new-blue;
    border-left: solid 6px $new-yellow;
    padding: 0.5em 1em;
    margin-left: 5em;
    margin-bottom: 2em;
    @media screen and (max-width: 1200px) {
      margin-left: 2em;
    }
    @media screen and (max-width: 650px) {
      margin-left: 0em;
    }
  }
  .container-box {
    .content-area {
      display: flex;
      max-width: 75em;
      margin: 0 auto;
      gap: 1.5em;
      align-items: center;
      .text-area {
        width: 50%;
        .simple {
          font-size: 32px;
        }
        .blue {
          font-size: 3em;
          font-weight: bold;
          background: -webkit-linear-gradient(
            0deg,
            rgba(0, 90, 209, 1) 0%,
            rgba(0, 183, 223, 1) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0.3em;
          line-height: 1.1em;
          margin-bottom: 1em;
        }
        .button {
          a {
            width: max-content;
            font-family: $ubuntu-typography;
            font-size: 15px;
            border: solid 3px $new-yellow;
            color: $new-yellow;
            font-weight: bold;
            padding: 1.2em 3.3em;
            border-radius: 10px;
            cursor: pointer;
            position: relative;
            isolation: auto;
            z-index: 10;
            overflow: hidden;
            display: block;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            gap: 0.5rem;
            &:hover {
              color: white;
              &::before {
                right: 0;
                width: 100%;
                transform: scale(1.5);
                transition-duration: 700ms;
              }
            }

            &:disabled {
              opacity: 0.5;
              pointer-events: none;
            }

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              transition: all 700ms;
              right: -100%;
              border-radius: 9999px;
              background-color: $new-yellow;
              z-index: -10;
              aspect-ratio: 1 / 1;
            }
            &:active {
              background-color: darken($new-yellow, 5%);
            }
          }
        }
      }
      .image-area {
        width: 50%;
        img {
          width: 100%;
          height: auto;
        }
      }
      @media screen and (max-width: 950px) {
        flex-direction: column;
        .text-area {
          width: 100%;
        }
        .image-area {
          width: 100%;
        }
      }
    }
  }
}
